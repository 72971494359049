import { useLocalStorage } from 'usehooks-ts'

export const useNotification = <S>(
  key: string,
  initialValue: S,
): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [state, setState] = useLocalStorage(
    `uls:Notification.${key}`,
    initialValue,
  )

  return [state, setState]
}
