import { useState, useEffect } from 'react'

export const useLocalStorage = <S>({
  key: initialKey,
  initialState,
  reducer,
}: {
  key: string
  initialState: S
  reducer?: React.Reducer<S, unknown>
}): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const key = `uls:${initialKey}`

  const [state, setState] = useState(() => {
    if (typeof window === 'undefined') return initialState

    const item = localStorage.getItem(key)

    try {
      return item ? JSON.parse(item) : initialState
    } catch (error) {
      return initialState
    }
  })

  const dispatch = reducer
    ? (action: unknown) => {
        const nextState = reducer(state, action)
        setState(nextState)
        return action
      }
    : setState

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  return [state, dispatch]
}
