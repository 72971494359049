import { useEffect, useState } from 'react'
import Head from 'next/head'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { DEFAULT_LOCALE, loadCatalog } from '@src/Lingui/helpers'
import { Loader } from '@googlemaps/js-api-loader'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '@src/Styles/theme'
import '@src/Styles/global.css'
import Loading from '@src/Loading'
import Typography from '@src/Typography'
import Layout from '@src/Layout'
import User from '@src/User'
import Snackbar from '@src/Snackbar'
import SuspenseBoundary from '@src/SuspenseBoundary'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import LaunchDarkly from '@src/LaunchDarkly'

export const GOOGLE_API_KEY = 'AIzaSyDzYsF_sF8N6drdFyC_KUt5XTgSqO0It9Y'

dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

i18n.loadAndActivate({ locale: DEFAULT_LOCALE, messages: {} })

declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage(msg: string): void
    }
  }
}

const App = (props: AppProps) => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window['versions'] = {
      wasm: process.env.NEXT_PUBLIC_WASM_VERSION,
      commit: process.env.NEXT_PUBLIC_GITHUB_SHA,
      tag: process.env.NEXT_PUBLIC_IMAGE_TAG,
    }
  }

  const { Component, pageProps } = props
  const [hasLoaded, setHasLoaded] = useState(false)
  const router = useRouter()
  const { ln } = router.query

  useEffect(() => {
    if (router.isReady) {
      loadCatalog(ln === 'zh' ? 'zh' : null, setHasLoaded)
    }
  }, [ln, router.isReady])

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyDzYsF_sF8N6drdFyC_KUt5XTgSqO0It9Y',
      version: 'quarterly',
      libraries: ['places'],
    })

    loader.importLibrary('places')
  }, [])

  return (
    <>
      <I18nProvider i18n={i18n}>
        <Head>
          <title>Wonders - Welcome</title>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Head>

        <LaunchDarkly>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {hasLoaded && router.isReady ? (
              <Typography>
                <Snackbar>
                  <User>
                    <SuspenseBoundary>
                      <Layout>
                        <Component {...pageProps} />
                      </Layout>
                    </SuspenseBoundary>
                  </User>
                </Snackbar>
              </Typography>
            ) : (
              <Loading />
            )}
          </ThemeProvider>
        </LaunchDarkly>
      </I18nProvider>
    </>
  )
}

export default App
