import Head from 'next/head'
import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { t } from '@lingui/macro'
import { AppBar, Box } from '@mui/material'
import Logo from '@src/Layout/Logo'
import { colors, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import LoginWebView from '@src/Login/LoginWebView'
import { useWonders } from '@src/Fetch/helpers'
import AuthForm from '@src/Auth/AuthForm'
import { useAuth } from '@src/Auth/helpers'

const Auth = () => {
  const router = useRouter()
  const { webview } = router.query

  const { typography } = useTypographyContext()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [lookupToken, setLookupToken] = useState<string | null>(null)

  const hasLoaded = useRef(false)

  const { data, error } = useWonders(
    lookupToken != null
      ? [
          'get_sms_auth_token',
          { token: lookupToken },
          { refreshInterval: 1000, suspense: false },
        ]
      : null,
  )

  const { redirectUrl } = useAuth({ data, error, hasLoaded })

  useEffect(() => {
    if (redirectUrl == null) return
    window.location.reload()
  }, [redirectUrl, router])

  return (
    <>
      <Head>
        <title>{t`Login`}</title>
      </Head>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          ...typography.body.medium,
        }}
      >
        {webview === 'true' ? (
          <LoginWebView />
        ) : (
          <>
            <AppBar
              component="nav"
              sx={{
                background: colors.primary[500],
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div css={{ width: 211, height: 64, paddingBlock: 22 }}>
                <Logo />
              </div>
            </AppBar>

            <Box
              component="main"
              css={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                paddingTop: spacing.colossal,
                background: colors.white[100],
              }}
            >
              <AuthForm
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                setLookupToken={setLookupToken}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  )
}

export default Auth
