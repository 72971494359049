import { Trans } from '@lingui/macro'
import spacing from '@src/Styles/spacing'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const SuspenseBoundaryFallback = () => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        gap: spacing.base,
        fontSize: 21,
      }}
    >
      <ErrorOutlineIcon />
      <div>
        <Trans>Something went wrong. Please try refreshing.</Trans>
      </div>
    </div>
  )
}

export default SuspenseBoundaryFallback
