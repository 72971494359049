import { useEffect, useRef } from 'react'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
// import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import { useFlags } from 'launchdarkly-react-client-sdk'

const environment =
  process.env.NEXT_PUBLIC_IMAGE_TAG === 'undefined' ||
  process.env.NEXT_PUBLIC_IMAGE_TAG?.includes('dev')
    ? 'development'
    : process.env.NEXT_PUBLIC_IMAGE_TAG?.includes('qa')
      ? 'qa'
      : 'production'

const version =
  environment === 'development'
    ? 'development'
    : process.env.NEXT_PUBLIC_IMAGE_TAG

export const useFaro = () => {
  const { cmaGrafana } = useFlags()

  const isInitialized = useRef(false)

  useEffect(() => {
    const initialize = async () => {
      if (environment === 'development' || cmaGrafana === false) return

      if (isInitialized.current) {
        return
      }

      isInitialized.current = true

      initializeFaro({
        url: 'https://faro-collector-prod-us-central-0.grafana.net/collect/094cc183e5ab6739978b5fcf02c49f1e',
        app: {
          name: 'greendot-cma',
          version,
          environment,
        },

        instrumentations: [
          // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
          ...getWebInstrumentations(),
        ],
      })
    }

    initialize()
  }, [cmaGrafana])

  return null
}
