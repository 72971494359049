import { useTypographyContext } from '@src/Typography'

const Header = ({ label }: { label: React.ReactNode }) => {
  const { scale, typography } = useTypographyContext()

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        minHeight: 56 * scale,
        ...typography.title.large,
      }}
    >
      {label}
    </div>
  )
}

export default Header
