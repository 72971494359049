import { useRef, useState } from 'react'
import { colors, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import { Trans } from '@lingui/macro'
import { TextField } from '@mui/material'

const MAX_PHONE_E164_LENGTH = 12 // assume US number length

// reference: lib/rust/corelib/src/identity/phone_number.rs
const checkPhoneValid = (
  phone: string | null,
  isInputComplete: boolean = false,
) => {
  const valueE164 = getPhoneE164(phone ?? '')

  if (!isInputComplete) {
    return true
  }

  if (valueE164.startsWith('1') && valueE164.length === 11) {
    return true
  }

  if (!valueE164.startsWith('+') && valueE164.length > 10) {
    return false // no country code, max 10 digits for US
  }

  return valueE164.length === MAX_PHONE_E164_LENGTH
}

const isPhoneInputComplete = (value: string | null | undefined) => {
  if (!value) return false

  const valueE164 = getPhoneE164(value)
  return valueE164.length >= MAX_PHONE_E164_LENGTH
}

const getPhoneE164 = (value: string): string => {
  let valueE164 = value.replace(/[^0-9+]/g, '')
  if (
    valueE164.startsWith('1') &&
    valueE164.length === MAX_PHONE_E164_LENGTH - 1
  ) {
    valueE164 = `+${valueE164}`
  }
  if (
    !valueE164.startsWith('+') &&
    valueE164.length >= MAX_PHONE_E164_LENGTH - 2
  ) {
    valueE164 = `+1${valueE164}`
  }
  return valueE164
}

const formatPhone = (phone: string | null | undefined) => {
  if (phone == null) return null

  if (phone.startsWith('+') && !phone.startsWith('+1')) {
    return phone // not US phone number, without formatted
  }

  const cleaned = ('' + phone).replace(/^\+\d{1}/, '')
  let formatted = ''
  if (cleaned.length <= 3) {
    formatted = cleaned
  } else if (cleaned.length > 3 && cleaned.length <= 6) {
    formatted = cleaned.slice(0, 3) + '-' + cleaned.slice(3)
  } else if (cleaned.length > 6) {
    formatted =
      cleaned.slice(0, 3) + '-' + cleaned.slice(3, 6) + '-' + cleaned.slice(6)
  }
  return formatted
}

const AuthTelInput = ({
  label,
  value,
  onChange,
  ...props
}: {
  label?: string
  value: string
  onFocus?: () => void
  onChange?: ({ value, isValid }: { value: string; isValid: boolean }) => void
  // onBlur?: ({ value, isValid }: { value: string; isValid: boolean }) => void
}) => {
  const { typography } = useTypographyContext()

  const valueE164 = getPhoneE164(value)

  const initialValue = useRef(valueE164)

  const formattedValue = formatPhone(valueE164) ?? ''

  const [isFocused, setIsFocused] = useState(false)

  const isInputComplete = isFocused
    ? isPhoneInputComplete(formattedValue)
    : true

  const isMaybeValid =
    value === initialValue.current ||
    checkPhoneValid(formattedValue, isInputComplete)

  return (
    <>
      <TextField
        type="tel"
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        value={formattedValue}
        onFocus={() => {
          setIsFocused(true)
        }}
        onChange={(e) => {
          const value = e.target.value
          const valueE164 = getPhoneE164(value)
          const newValue = formatPhone(valueE164) ?? ''
          onChange?.({
            value: newValue,
            isValid: checkPhoneValid(valueE164, true),
          })
        }}
        css={{
          width: '100%',
          height: 56,
        }}
        {...props}
      />
      <div
        css={{
          marginTop: spacing.hairline,
          ...typography.label.medium,
          color: colors.error[500],
          paddingLeft: spacing.small,
          paddingRight: spacing.small,
        }}
      >
        {!isMaybeValid && <Trans>Invalid phone number</Trans>}&nbsp;
      </div>
    </>
  )
}

export default AuthTelInput
