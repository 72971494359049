const config = {
  locales: ['en', 'zh'],
  catalogs: [
    {
      path: '<rootDir>/locales/{locale}',
      include: ['<rootDir>/src'],
      exclude: ['**/node_modules/**'],
    },
  ],
  compileNamespace: 'ts',
  format: 'po',
}

export default config
