import { TextField } from '@mui/material'

const AuthEmailInput = ({
  label,
  value,
  onChange,
  ...props
}: {
  label?: string
  value: string
  onChange?: (value: string) => void
}) => {
  return (
    <TextField
      type={'email'}
      label={label}
      InputLabelProps={{
        shrink: true,
      }}
      value={value}
      onChange={(e) => {
        const value = e.target.value
        if (onChange != null) onChange(value)
      }}
      css={{
        width: '100%',
        height: 56,
      }}
      {...props}
    />
  )
}

export default AuthEmailInput
