import { ReactNode, Suspense } from 'react'
import { ErrorBoundary } from '@sentry/react'

import Loading from '@src/Loading'

import SuspenseBoundaryFallback from '@src/SuspenseBoundary/SuspenseBoundaryFallback'

const SuspenseBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <ErrorBoundary fallback={SuspenseBoundaryFallback}>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

export default SuspenseBoundary
