import { LDProvider } from 'launchdarkly-react-client-sdk'

const environment =
  process.env.NEXT_PUBLIC_IMAGE_TAG === 'undefined'
    ? 'local'
    : process.env.NEXT_PUBLIC_IMAGE_TAG?.includes('dev')
      ? 'development'
      : process.env.NEXT_PUBLIC_IMAGE_TAG?.includes('qa')
        ? 'qa'
        : 'production'

const LD_CLIENT_SIDE_ID = {
  local: '663a8a327f73e7101b8bea5d',
  development: '663a8b22764ec61008c53a1c',
  qa: '663a8b50d6d8d51024e27414',
  production: '663a8a327f73e7101b8bea5e',
}

const LaunchDarkly = ({ children }: { children: React.ReactNode }) => {
  return (
    <LDProvider clientSideID={LD_CLIENT_SIDE_ID[environment]}>
      {children}
    </LDProvider>
  )
}

export default LaunchDarkly
