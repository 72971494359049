import StoreIcon from '@mui/icons-material/Store'
import { colors, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import Link from 'next/link'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useUserContext } from '@src/User'

const RNumberBanner = () => {
  const { typography } = useTypographyContext()
  const { me, location } = useUserContext()

  const isWebView = 'ReactNativeWebView' in window

  if (me == null) return null

  const hasMultipleLocations =
    me.enterprises.length > 1 ||
    (me.enterprises.length === 1 && me.enterprises[0].locations.length > 1)

  const Parent = hasMultipleLocations ? Link : 'div'

  if (location == null) return null

  return (
    <Parent
      href={`/restaurants`}
      css={{
        textDecoration: 'none',
        height: 64,
        width: '100%',
        position: 'fixed',
        top: isWebView ? 0 : spacing.colossal,
        zIndex: 1000,
      }}
    >
      <div
        css={{
          height: 64,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: spacing.normal,
          svg: { color: colors.primary2[500] },
          gap: spacing.normal,
          background: colors.white[500],
          borderBottom: `1px solid ${colors.primary2[50]}`,
          ...typography.title.medium,
          color: colors.neutrals[500],
          cursor: hasMultipleLocations ? 'pointer' : 'default',
          ...(hasMultipleLocations
            ? {
                ':hover': {
                  background: colors.primary2[50],
                },
                ':active': {
                  background: colors.primary2[500],
                  '*': {
                    color: colors.white[500],
                  },
                },
              }
            : {}),
        }}
      >
        <div css={{ display: 'flex', gap: spacing.base }}>
          <StoreIcon />
          <div>{`${location.name} (${location.r_number})`}</div>
        </div>

        {hasMultipleLocations && <ChevronRightIcon />}
      </div>
    </Parent>
  )
}

export default RNumberBanner
