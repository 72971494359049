import { CircularProgress } from '@mui/material'

const Loading = () => {
  return (
    <div
      css={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        svg: { height: '100%', width: '100%' },
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default Loading
