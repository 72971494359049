import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'

const LayoutButton = ({
  variant,
  label,
  isSelected,
}: {
  variant: 'dark' | 'light'
  label: React.ReactNode
  isSelected: boolean
}) => {
  const { typography } = useTypographyContext()

  return (
    <>
      <button
        type="button"
        disabled={isSelected}
        css={{
          border: 'none',
          background: isSelected ? colors.primary2[50] : 'none',
          borderRadius: constants.borderRadius.medium,
          color: isSelected
            ? colors.primary2[500]
            : variant === 'dark'
              ? colors.white[50]
              : colors.neutrals[500],
          ...typography.button.medium,
          textTransform: 'uppercase',
          paddingInline: spacing.normal,
          paddingBlock: spacing.base,
          ...(variant === 'light' && { width: '100%', textAlign: 'left' }),
          cursor: isSelected ? 'default' : 'pointer',
          ':hover:enabled': {
            color: colors.white[50],
            background: colors.primary2[500],
          },
          ':active:enabled': {
            background: colors.primary2[900],
          },
        }}
      >
        {label}
      </button>
    </>
  )
}

export default LayoutButton
