/* tslint:disable */
/* eslint-disable */
const borderRadius = {
  none: 0,
  small: 5,
  medium: 10,
  xxlarge: 100,
}

const borderWidths = { none: 0, regular: 1, medium: 2, large: 4 }

const borders = {}

const opacity = {
  quarter: 0.25,
  40: 0.4,
  half: 0.5,
  full: 1,
}

const boxShadows = {
  navbar:
    '0px 2px 4px -1px rgba(0, 0, 0, 0.20), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
}

const constants = {
  borderRadius,
  borderWidths,
  borders,
  opacity,
  boxShadows,
}

export default constants
