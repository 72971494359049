import type { NextApiRequest, NextApiResponse } from 'next'

export const version =
  process.env.NEXT_PUBLIC_IMAGE_TAG !== 'undefined' &&
  process.env.NEXT_PUBLIC_IMAGE_TAG != null
    ? process.env.NEXT_PUBLIC_IMAGE_TAG
    : 'development'

type ResponseData = {
  version: string
}

const handler = (_: NextApiRequest, res: NextApiResponse<ResponseData>) => {
  res.status(200).json({ version })
}

export default handler
