const colors = {
  transparent: 'transparent',
  black: 'black',
  wondersBlue: {
    50: '#e7f3ff',
    100: '#b5daff',
    200: '#91c9ff',
    300: '#5fb0ff',
    400: '#40a1ff',
    500: '#1089ff',
    600: '#0f7de8',
    700: '#0b61b5',
    800: '#094b8c',
    900: '#073a6b',
  },
  // dark blue
  primary: {
    50: '#e6e8ec',
    100: '#b0b7c5',
    200: '#8a95a9',
    300: '#546481',
    400: '#334669',
    500: '#001843',
    600: '#001130',
    700: '#000d25',
    800: '#000d25',
    900: '#000a1c',
  },
  // blue
  primary2: {
    25: '#f5f7Fb',
    50: '#ebeff7',
    100: '#c0cfe5',
    200: '#a2b7d9',
    300: '#7796c7',
    400: '#5d82bd',
    500: '#3463ac',
    600: '#2f5a9d',
    700: '#25467a',
    800: '#1d365f',
    900: '#162a48',
  },
  // green
  dish: {
    50: '#e8f5ea',
    100: '#b8e0be',
    200: '#96d19e',
    300: '#66bb72',
    400: '#48ae56',
    500: '#1a9a2c',
    600: '#188c28',
    700: '#126d1f',
    800: '#0e5518',
    900: '#0b4112',
  },
  // orange
  variants: {
    50: '#fff5ef',
    100: '#ffdfce',
    200: '#ffd0b6',
    300: '#ffba95',
    400: '#ffad81',
    500: '#ff9861',
    600: '#e88a58',
    700: '#b56c45',
    800: '#8c5435',
    900: '#6b4029',
  },
  // light blue
  options: {
    50: '#eaf5fb',
    100: '#bcdff3',
    200: '#9cd0ed',
    300: '#6fbae4',
    400: '#53addf',
    500: '#2898d7',
    600: '#248ac4',
    700: '#1c6c99',
    800: '#165476',
    900: '#11405a',
  },
  // purple
  modifiers: {
    50: '#f8edf6',
    100: '#e9c6e4',
    200: '#deaad7',
    300: '#cf83c4',
    400: '#c66bb9',
    500: '#b846a7',
    600: '#a74098',
    700: '#833277',
    800: '#65275c',
    900: '#4d1d46',
  },
  // red
  error: {
    50: '#fdebea',
    100: '#fac1be',
    200: '#f7a39f',
    300: '#f47a73',
    400: '#f16058',
    500: '#ee382e',
    600: '#d9332a',
    700: '#a92821',
    800: '#831f19',
    900: '#641813',
  },
  // grey
  neutrals: {
    25: '#F5F5F5',
    50: '#ebebeb',
    100: '#c0c0c0',
    200: '#a1a1a1',
    300: '#767676',
    400: '#5c5c5c',
    500: '#333333',
    600: '#2e2e2e',
    700: '#242424',
    800: '#1c1c1c',
    900: '#151515',
  },
  white: {
    50: '#ffffff',
    100: '#ffffff',
    200: '#ffffff',
    300: '#ffffff',
    400: '#ffffff',
    500: '#ffffff',
    600: '#e8e8e8',
    700: '#b5b5b5',
    800: '#8c8c8c',
    900: '#6b6b6b',
  },
}

export default colors
